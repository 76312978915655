  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

  :root {
    --weight-medium: 600;

    --toolbar-height: 56px;
    --ion-font-family: 'lato', sans-serif;
    font-family: var(--ion-font-family) !important;
  }

  .t-overline {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  .t-underline {
    font-size: 11px;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-all;
    color: rgba(0, 0, 0, 0.6);
  }

  .t-caption {
    font-size: 13px;
    letter-spacing: 0.4px;
  }

  .t-button {
    font-size: 15px;
    letter-spacing: 1.25px;
    font-weight: var(--weight-medium);
    text-transform: uppercase;
  }

  .t-body2 {
    font-size: 15px;
    letter-spacing: 0.25px;
  }

  .t-body1 {
    font-size: 17px;
    letter-spacing: 0.5px;
  }

  .t-subtitle2 {
    font-size: 15px;
    font-weight: var(--weight-medium);
    letter-spacing: 0.1px;
  }

  .t-subtitle1 {
    font-size: 17px;
    letter-spacing: 0.15px;
  }

  .t-h6 {
    font-size: 21px;
    letter-spacing: 0.15px;
    font-weight: var(--weight-medium);
  }

  .t-h5 {
    font-size: 25px;
    letter-spacing: 0px;
  }

  .t-h45{
    font-size: 33px;
    letter-spacing: 0.25px;
  }

  .t-h4 {
    font-size: 36px;
    letter-spacing: 0.25px;
  }

  .t-h3 {
    font-size: 50px;
    letter-spacing: 0px;
  }

  .t-h2 {
    font-size: 63px;
    letter-spacing: -0.5px;
    font-weight: lighter;
  }

  .t-h1 {
    font-size: 101px;
    letter-spacing: -1.5px;
    font-weight: lighter;
  }

  .t-a-l{
    text-align: left !important;
  }
  .t-a-r{
    text-align: right !important;
  }
  .t-a-c{
    text-align: center !important;
  }

  .tc-b {
    color: rgba(0, 0, 0);
  }

  .tc-b80 {
    color: rgba(0, 0, 0, 0.8);
  }

  .tc-b75 {
    color: rgb(0, 0, 0, 0.75);
  }

  .tc-b60 {
    color: rgba(0, 0, 0, 0.6);
  }

  .tc-b40 {
    color: rgb(0, 0, 0, 0.40);
  }

  .tc-w {
    color: rgb(255, 255, 255);
  }

  .tc-w90 {
    color: rgb(255, 255, 255, 0.9);
  }
  .tc-w80 {
    color: rgb(255, 255, 255, 0.8);
  }

  .tc-w75 {
    color: rgb(255, 255, 255, 0.75);
  }

  .tc-w60 {
    color: rgb(255, 255, 255, 0.6);
  }

  .tc-w30 {
    color: rgb(255, 255, 255, 0.4);
  }
