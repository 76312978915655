.side-bar-menu {
  background-color: var(--c-grey) !important;
  z-index: var(--z-index-sidebar);
  border: none !important;

  .p-sidebar-close {
    &:hover {
      color: rgba(255, 255, 255, 1) !important;
    }

    &:active,
    &:focus {
      box-shadow: inset 0 0 0 0.2rem rgb(218, 218, 218, 0.5);
    }
  }

  .profile-companie{
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .companie-image{
      height: 80px;
      width: 100%;
      margin-right: 0.5em;
      content: url(../../../../assets/images/hm_logo_header-trans.svg);
    }
  }

  .sidebar-title {
    margin: 16px 0px;
    user-select: none;
  }

  .sidebar-profile-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;

    .profile-image {
      height: 50px;
      width: 50px;
      border-radius: 100%;
      margin-right: 0.5em;
      border: 1px solid rgba(255, 255, 255, 0.3);
      content: url(../../../../assets/images/user-icon.png);
    }

    .profile-info {
      display: flex;
      flex-direction: column;

      * {
        user-select: none;
      }
    }
  }

  * {
    scrollbar-width: auto;
    scrollbar-color: #2f3640 rgba(255, 255, 255, 0.5);
  }

  *::-webkit-scrollbar {
    width: 15px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);

  }

  .menu.p-menu {
    width: 100%;
    background-color: transparent;
    overflow-y: 0px;
    max-height: 65vh;

    // Scrollbar styles


    &,
    * {
      border: none;
    }

    .p-submenu-header {
      background-color: transparent;
      padding-bottom: 5px;
      padding-left: 0px;
      color: var(--c-white);
    }

    .p-menuitem-link {
      border-radius: 3px;

      &.p-menuitem-link-active {
        background-color: rgba(255, 255, 255, 0.3);

        .p-menuitem-text,
        .p-menuitem-icon {
          color: var(--c-white) !important;
        }

        transition:all 150ms;
      }

      &:hover {
        background-color: #7db2e1;
        transition: all ease-in-out 150ms;
      }

      &:active,
      &:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.2rem #dadada;
        transition: all ease-in 150ms;
      }

      .p-menuitem-text,
      .p-menuitem-icon {
        color: var(--c-white75);
      }

      transition: all ease-in-out 150ms;
    }
  }
}