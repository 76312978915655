:root {
  --c-grey: #3C4858; /*  Color de Template P-Menu*/
  --c-main: #b33939;
  --c-main-hover:#d14b4b;
  --c-secondary: #3498db;
  --c-white: rgba(255, 255, 255);
  --c-white80: rgba(255, 255, 255, 0.8);
  --c-white75: rgba(255, 255, 255, 0.75);
  --c-white60: rgba(255, 255, 255, 0.6);
  --c-white40: rgba(255, 255, 255, 0.4);

  --c-black: rgba(0, 0, 0);
  --c-black80: rgba(0, 0, 0, 0.8);
  --c-black75: rgba(0, 0, 0, 0.75);
  --c-black60: rgba(0, 0, 0, 0.6);
  --c-black40: rgba(0, 0, 0, 0.4);
}

.bgc-main {
  /*background-color: #2454b8;*/
  background-color: #3C4858;
}

.bgc-secondary {
  background-color: #3498db;
}

.bgc-grey {
  background-color: #4b4b4b;
}
