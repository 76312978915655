/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
/*@import "~primeicons/primeicons.css";*/
/*Estilos De Template*/
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import '/node_modules/@fortawesome/fontawesome-free/css/all.css';
:root {
  --c-grey: #3C4858; /*  Color de Template P-Menu*/
  --c-main: #b33939;
  --c-main-hover:#d14b4b;
  --c-secondary: #3498db;
  --c-white: rgba(255, 255, 255);
  --c-white80: rgba(255, 255, 255, 0.8);
  --c-white75: rgba(255, 255, 255, 0.75);
  --c-white60: rgba(255, 255, 255, 0.6);
  --c-white40: rgba(255, 255, 255, 0.4);
  --c-black: rgba(0, 0, 0);
  --c-black80: rgba(0, 0, 0, 0.8);
  --c-black75: rgba(0, 0, 0, 0.75);
  --c-black60: rgba(0, 0, 0, 0.6);
  --c-black40: rgba(0, 0, 0, 0.4);
}

.bgc-main {
  /*background-color: #2454b8;*/
  background-color: #3C4858;
}

.bgc-secondary {
  background-color: #3498db;
}

.bgc-grey {
  background-color: #4b4b4b;
}

.side-bar-menu {
  background-color: var(--c-grey) !important;
  z-index: var(--z-index-sidebar);
  border: none !important;
}
.side-bar-menu .p-sidebar-close:hover {
  color: rgb(255, 255, 255) !important;
}
.side-bar-menu .p-sidebar-close:active, .side-bar-menu .p-sidebar-close:focus {
  box-shadow: inset 0 0 0 0.2rem rgba(218, 218, 218, 0.5);
}
.side-bar-menu .profile-companie {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.side-bar-menu .profile-companie .companie-image {
  height: 80px;
  width: 100%;
  margin-right: 0.5em;
  content: url(../../../../assets/images/hm_logo_header-trans.svg);
}
.side-bar-menu .sidebar-title {
  margin: 16px 0px;
  user-select: none;
}
.side-bar-menu .sidebar-profile-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}
.side-bar-menu .sidebar-profile-block .profile-image {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  margin-right: 0.5em;
  border: 1px solid rgba(255, 255, 255, 0.3);
  content: url(../../../../assets/images/user-icon.png);
}
.side-bar-menu .sidebar-profile-block .profile-info {
  display: flex;
  flex-direction: column;
}
.side-bar-menu .sidebar-profile-block .profile-info * {
  user-select: none;
}
.side-bar-menu * {
  scrollbar-width: auto;
  scrollbar-color: #2f3640 rgba(255, 255, 255, 0.5);
}
.side-bar-menu *::-webkit-scrollbar {
  width: 15px;
}
.side-bar-menu *::-webkit-scrollbar-track {
  background: transparent;
}
.side-bar-menu *::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}
.side-bar-menu .menu.p-menu {
  width: 100%;
  background-color: transparent;
  overflow-y: 0px;
  max-height: 65vh;
}
.side-bar-menu .menu.p-menu,
.side-bar-menu .menu.p-menu * {
  border: none;
}
.side-bar-menu .menu.p-menu .p-submenu-header {
  background-color: transparent;
  padding-bottom: 5px;
  padding-left: 0px;
  color: var(--c-white);
}
.side-bar-menu .menu.p-menu .p-menuitem-link {
  border-radius: 3px;
  transition: all ease-in-out 150ms;
}
.side-bar-menu .menu.p-menu .p-menuitem-link.p-menuitem-link-active {
  background-color: rgba(255, 255, 255, 0.3);
  transition: all 150ms;
}
.side-bar-menu .menu.p-menu .p-menuitem-link.p-menuitem-link-active .p-menuitem-text,
.side-bar-menu .menu.p-menu .p-menuitem-link.p-menuitem-link-active .p-menuitem-icon {
  color: var(--c-white) !important;
}
.side-bar-menu .menu.p-menu .p-menuitem-link:hover {
  background-color: #7db2e1;
  transition: all ease-in-out 150ms;
}
.side-bar-menu .menu.p-menu .p-menuitem-link:active, .side-bar-menu .menu.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #dadada;
  transition: all ease-in 150ms;
}
.side-bar-menu .menu.p-menu .p-menuitem-link .p-menuitem-text,
.side-bar-menu .menu.p-menu .p-menuitem-link .p-menuitem-icon {
  color: var(--c-white75);
}

.p-breadcrumb ul li .p-menuitem-link {
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #d14b4b;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #333333;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #333333;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #333333;
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #333333;
}

.p-contextmenu {
  padding: 0;
  background: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  width: 12.5rem;
}

.p-contextmenu .p-menuitem-link {
  padding: 0.857rem;
  color: #333333;
  border-radius: 0;
  transition: background-color 0.2s, box-shadow 0.2s;
  user-select: none;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #333333;
  margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #d14b4b;
}

.p-contextmenu .p-submenu-list {
  padding: 0;
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #eaeaea;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

.p-contextmenu .p-menu-separator {
  border-top: 1px solid #d8dae2;
  margin: 0;
}

.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-megamenu {
  padding: 0.5rem;
  background: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.857rem;
  color: #333333;
  border-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
  user-select: none;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #333333;
  margin-right: 0.5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #333333;
  margin-left: 0.5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #d14b4b;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #333333;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #333333;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #333333;
}

.p-megamenu .p-menuitem-link {
  padding: 0.857rem;
  color: #333333;
  border-radius: 0;
  transition: background-color 0.2s, box-shadow 0.2s;
  user-select: none;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #333333;
  margin-right: 0.5rem;
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #d14b4b;
}

.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.857rem;
  color: #333333;
  background: #f4f4f4;
  font-weight: 700;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-megamenu .p-megamenu-submenu {
  padding: 0;
  width: 12.5rem;
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #d8dae2;
  margin: 0;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #eaeaea;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0;
}

.p-menu {
  padding: 0;
  background: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  width: 12.5rem;
}

.p-menu .p-menuitem-link {
  padding: 0.857rem;
  color: #333333;
  border-radius: 0;
  transition: background-color 0.2s, box-shadow 0.2s;
  user-select: none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #333333;
  margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #d14b4b;
}

.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.857rem;
  color: #333333;
  background: #f4f4f4;
  font-weight: 700;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-menu .p-menu-separator {
  border-top: 1px solid #d8dae2;
  margin: 0;
}

.p-menubar {
  padding: 0.5rem;
  background: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
}

.p-menubar .p-menuitem-link {
  padding: 0.857rem;
  color: #333333;
  border-radius: 0;
  transition: background-color 0.2s, box-shadow 0.2s;
  user-select: none;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #333333;
  margin-right: 0.5rem;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #d14b4b;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.857rem;
  color: #333333;
  border-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
  user-select: none;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #333333;
  margin-right: 0.5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #333333;
  margin-left: 0.5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #d14b4b;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #333333;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #333333;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #333333;
}

.p-menubar .p-submenu-list {
  padding: 0;
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  width: 12.5rem;
}

.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #d8dae2;
  margin: 0;
}

.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #eaeaea;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #333333;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: #333333;
    background: #eaeaea;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #d14b4b;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0;
    background: #ffffff;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #d8dae2;
    margin: 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.857rem;
    color: #333333;
    border-radius: 0;
    transition: background-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #d14b4b;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.571rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 4.285rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.999rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 7.713rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 9.427rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 0.857rem 1rem;
  border: 1px solid #007ad9;
  color: #ffffff;
  background: #007ad9;
  font-weight: 700;
  border-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #d14b4b;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #dbdbdb;
  border-color: #dbdbdb;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #007ad9;
  border-color: #007ad9;
  color: #ffffff;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #005b9f;
  background: #005b9f;
  color: #ffffff;
}

.p-panelmenu .p-panelmenu-content {
  padding: 0;
  border: 1px solid #c8c8c8;
  background: #ffffff;
  color: #333333;
  margin-bottom: 2px;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.857rem;
  color: #333333;
  border-radius: 0;
  transition: background-color 0.2s, box-shadow 0.2s;
  user-select: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #333333;
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #d14b4b;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 2px;
}

.p-slidemenu {
  padding: 0;
  background: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  width: 12.5rem;
}

.p-slidemenu .p-menuitem-link {
  padding: 0.857rem;
  color: #333333;
  border-radius: 0;
  transition: background-color 0.2s, box-shadow 0.2s;
  user-select: none;
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #333333;
  margin-right: 0.5rem;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #d14b4b;
}

.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

.p-slidemenu .p-slidemenu-list {
  padding: 0;
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #eaeaea;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #333333;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #333333;
}

.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #d8dae2;
  margin: 0;
}

.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}

.p-slidemenu .p-slidemenu-backward {
  padding: 0.857rem;
  color: #333333;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  background: #ffffff;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #333333;
  border: 1px solid #dee2e6;
  background: #ffffff;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #848484;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #d14b4b;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #c0392b;
  color: #ffffff;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 700;
  color: #333333;
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #d8dae2;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.12);
  border-width: 0 0 1px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: none;
  border-width: 0 0 0 0;
  border-color: transparent transparent transparent transparent;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.6);
  padding: 1rem 1.5rem;
  font-weight: 500;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: none;
  margin: 0 0 0 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

/* #b33939 */
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  /* background: rgba(63, 81, 181, 0.04); */
  background-color: rgba(52, 152, 219, 0.04);
  border-color: transparent;
  color: rgba(0, 0, 0, 0.6);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: transparent;
  color: #3498db;
}

.p-tabmenu .p-tabmenu-nav {
  position: relative;
}

.p-tabmenu .p-tabmenu-nav li .p-menuitem-link {
  transition: background-color 0.2s;
  border-radius: 0;
}

.p-tabmenu .p-tabmenu-nav li .p-menuitem-link > .p-ink {
  /* background-color: rgba(63, 81, 181, 0.16); */
  background-color: rgba(52, 152, 219, 0.16);
}

.p-tabmenu .p-tabmenu-nav li .p-menuitem-link:focus {
  /* background-color: rgba(63, 81, 181, 0.12); */
  background-color: rgba(52, 152, 219, 0.12);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenu-ink-bar {
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #3498db;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

/* .p-tabmenu .p-tabmenu-nav {
  background: transparent;
  border: 0 none;
  border-width: 1px;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 2px;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: 1px solid #007ad9;
  border-width: 1px;
  border-color: #007ad9;
  background: #007ad9;
  color: #ffffff;
  padding: 0.857rem 1rem;
  font-weight: 700;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -1px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #d14b4b;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #dbdbdb;
  border-color: #dbdbdb;
  color: #333333;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #007ad9;
  border-color: #007ad9;
  color: #ffffff;
} */
.p-tieredmenu {
  padding: 0;
  background: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  width: 12.5rem;
}

.p-tieredmenu .p-menuitem-link {
  padding: 0.857rem;
  color: #333333;
  border-radius: 0;
  transition: background-color 0.2s, box-shadow 0.2s;
  user-select: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #333333;
  margin-right: 0.5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #eaeaea;
}

.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem #d14b4b;
}

.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

.p-tieredmenu .p-submenu-list {
  padding: 0;
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #eaeaea;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #333333;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #333333;
}

.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #d8dae2;
  margin: 0;
}

.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

:root {
  --weight-medium: 600;
  --toolbar-height: 56px;
  --ion-font-family: "lato", sans-serif;
  font-family: var(--ion-font-family) !important;
}

.t-overline {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.t-underline {
  font-size: 11px;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-all;
  color: rgba(0, 0, 0, 0.6);
}

.t-caption {
  font-size: 13px;
  letter-spacing: 0.4px;
}

.t-button {
  font-size: 15px;
  letter-spacing: 1.25px;
  font-weight: var(--weight-medium);
  text-transform: uppercase;
}

.t-body2 {
  font-size: 15px;
  letter-spacing: 0.25px;
}

.t-body1 {
  font-size: 17px;
  letter-spacing: 0.5px;
}

.t-subtitle2 {
  font-size: 15px;
  font-weight: var(--weight-medium);
  letter-spacing: 0.1px;
}

.t-subtitle1 {
  font-size: 17px;
  letter-spacing: 0.15px;
}

.t-h6 {
  font-size: 21px;
  letter-spacing: 0.15px;
  font-weight: var(--weight-medium);
}

.t-h5 {
  font-size: 25px;
  letter-spacing: 0px;
}

.t-h45 {
  font-size: 33px;
  letter-spacing: 0.25px;
}

.t-h4 {
  font-size: 36px;
  letter-spacing: 0.25px;
}

.t-h3 {
  font-size: 50px;
  letter-spacing: 0px;
}

.t-h2 {
  font-size: 63px;
  letter-spacing: -0.5px;
  font-weight: lighter;
}

.t-h1 {
  font-size: 101px;
  letter-spacing: -1.5px;
  font-weight: lighter;
}

.t-a-l {
  text-align: left !important;
}

.t-a-r {
  text-align: right !important;
}

.t-a-c {
  text-align: center !important;
}

.tc-b {
  color: rgb(0, 0, 0);
}

.tc-b80 {
  color: rgba(0, 0, 0, 0.8);
}

.tc-b75 {
  color: rgba(0, 0, 0, 0.75);
}

.tc-b60 {
  color: rgba(0, 0, 0, 0.6);
}

.tc-b40 {
  color: rgba(0, 0, 0, 0.4);
}

.tc-w {
  color: rgb(255, 255, 255);
}

.tc-w90 {
  color: rgba(255, 255, 255, 0.9);
}

.tc-w80 {
  color: rgba(255, 255, 255, 0.8);
}

.tc-w75 {
  color: rgba(255, 255, 255, 0.75);
}

.tc-w60 {
  color: rgba(255, 255, 255, 0.6);
}

.tc-w30 {
  color: rgba(255, 255, 255, 0.4);
}

:root {
  --z-index-navbar: 1000;
  --navbar-height: 60px;
}

.panel-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.root {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}

.chart-card {
  background-color: #fff;
  position: relative;
  flex: 1 0 auto;
  margin: 1em;
  padding: 1em;
  border-radius: 5px;
}

body {
  background-color: #fafafa;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #2f3640 rgba(0, 0, 0, 0.5);
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}

/*Colores Institucionales*/
.principal1 {
  background-color: #3161C4;
}

.principa2 {
  background-color: #2f3640;
}

.principal3 {
  background-color: rgb(200, 172, 131);
}

.principal4 {
  background-color: rgb(155, 154, 153);
}

.principal5 {
  color: rgb(33, 25, 21);
}

/*
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}*/
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}