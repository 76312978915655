/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
/*@import "~primeicons/primeicons.css";*/

/*Estilos De Template*/
@import './app/shared/utils/styles/color.styles.scss';
@import './app/shared/utils/styles/sidebar.styles.scss';
@import './app/shared/utils/styles/menu.styles.scss';
@import './app/shared/utils/styles/text.styles.scss';
@import './app/shared/utils/styles/layout.styles.scss';
@import '/node_modules/@fortawesome/fontawesome-free/css/all.css';

body{
  background-color: #fafafa;
}


//scrollbar
* {
  scrollbar-width: thin;
  scrollbar-color: #2f3640 rgba(0, 0, 0, 0.5);
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);

}

/*Colores Institucionales*/
.principal1{
  background-color: #3161C4;
}

.principa2{
  background-color: #2f3640;
}

.principal3{
  background-color: rgba(200, 172, 131);
}


.principal4{
  background-color: rgba(155, 154, 153);
}

.principal5{
  color: rgb(33, 25, 21);
}





/*
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}*/

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
